<template>
  <ValidationObserver tag="div" ref="validator" v-slot="{ invalid }">
    <BaseModal np-fund-password-modal :show-title="false">
      <template #content>
        <div class="modal-body">
          <div v-if="isResult" class="result">
            <FontIcon :name="icon" :class="['icon',{'success' : isSuccess}]" />
            <p class="content-message" v-html="message" />
          </div>
          <div v-else class="content">
            <div class="message-group">
              <p class="content-title" v-html="title" />
              <p class="content-message" v-html="message" />
            </div>
            <InputFundPassword v-if="isLoad && !isResult" :model="fundModel" :mode="mode" :is-enable-fund-password="isEnableFundPassword" :error="errorModel" />
          </div>
        </div>
      </template>
      <template #floating>
        <div class="gp-modal-floating">
          <GpButton v-if="!isResult"  type="submit-green" @click="submit" :progress="progress" :disabled="!isLoad || !!invalid || disabled">{{ buttonText }}</GpButton>
          <GpButton v-else type="submit-green" @click="close">{{ $t('ok') }}</GpButton>
        </div>
      </template>
    </BaseModal>
  </ValidationObserver>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import { apiErrorCode } from '@/constants/base/apiErrorCode';
import { state } from '@shared/utils/storeUtils.mjs';
import InputFundPassword from '@/views/components/pages/my-page/template/inputs/InputFundPassword.vue';
import { FUND_MODE } from '@/constants/base/my-page';

export default {
  name: 'ChangeFundPasswordModal',
  components: { InputFundPassword, GpButton, GpDescription, BaseModal, FontIcon },
  data() {
    return {
      title : null,
      message: null,
      success: true,
      step: 'edit',
      progress: false,
      isLoad : false,
      isEnableFundPassword : false,
      mode : FUND_MODE.FUND,
      fundModel : {
        FundPassword : null,
        CurrentFundPassword : null,
        IsEnableFundPassword : false,
      },
      errorModel : {
        FundPassword : null,
        CurrentFundPassword : null,
        error : false,
      },
      successMessageModel :  {
        [FUND_MODE.FUND]: 'myInfo.fundPasswordEnabled',
        [FUND_MODE.CHANGE]: 'myInfo.fundPassword.changeSuccess',
        [FUND_MODE.DISABLE]: 'myInfo.fundPassword.disabledSuccess',
      },
    };
  },
  computed: {
    csEmail: state('env', 'csEmail'),
    userInfo: state('user', 'userInfo'),
    isSuccess() {
      return this.success;
    },
    icon() {
      return this.isSuccess ? 'check-over-circle-regular' : 'exclamation-circle-regular';
    },
    isResult() {
      return this.step === 'result';
    },
    isEdit() {
      return this.step === 'edit';
    },
    buttonText(){
      return this.mode === FUND_MODE.FUND ? this.$t('myInfo.enable') : this.$t('myInfo.disable')
    },
    disabled(){
      return this.errorModel.error
    }
  },
  watch:{
    'fundModel.FundPassword' : 'updateFundPassword',
    'fundModel.CurrentFundPassword' : 'updateFundPassword',
  },
  methods: {
    initialize(){
      console.log("?A?A?");
      this.isEnableFundPassword = this.userInfo.IsEnableFundPassword;
      if(this.isEnableFundPassword){
        this.title = this.$t('myInfo.title.disableFundPassword')
        this.message = this.$t('myInfo.fundPassword.noFundPasswordDesc');
        this.mode = FUND_MODE.DISABLE
        this.fundModel.IsEnableFundPassword = false;
      }else{
        this.title = this.$t('myInfo.title.enableFundPassword')
        this.message = this.$t('myInfo.fundPassword.noFundPasswordDesc');
        this.mode = FUND_MODE.FUND;
        this.fundModel.IsEnableFundPassword = true;
      }
      this.isLoad = true;
    },

    updateFundPassword(){
      this.errorModel.FundPassword = null;
      this.errorModel.CurrentFundPassword = null;
      this.errorModel.error = false;
    },

    close() {
      this.$modalCloseAll();
    },
    async submit() {
      this.progress = true;
      const r = await this.$services.user.changeNpFundPassword(this.fundModel);
      if (r?.error) {
        this.changeFundPasswordErrorHandler(r);
        this.progress = false;
        return;
      } else {
        await this.$myinfo.setPlayerInfo();
        this.success = true;
        const successKey = this.successMessageModel[this.mode];
        this.message = this.$t(successKey);
      }

      this.step = 'result';
      this.progress = false;
    },

    changeFundPasswordErrorHandler(error) {
      let { key, desc } = error;
      this.errorModel.error = true;
      switch (key) {
        case 'INVALID_FUND_PASSWORD_VERIFICATION' : {
          this.errorModel.CurrentFundPassword = this.$t(key);
          return;
        }
        case apiErrorCode.INVALID_REQUEST :
          key = 'INVALID_FUND_PASSWORD_LENGTH';
          break;
        case 'INVALID_FUND_PASSWORD' :
          key = 'INVALID_PASSWORD';
          break;
      }
      this.errorModel.FundPassword = this.$t(key);
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[np-fund-password-modal] {
  > .panel { .h(400); .rel(); .max-w(460);
    @media (max-height: 499px) {
      .min-h(100%);
    }
    .scroll-area {
      .h(calc(100% - 59px)); .flex(); .flex-dc();
      > .modal-body { flex: 1; }
    }
  }
  .content { .flex();.flex-dc();.gap(24);.pt(4); .h(100%);
    .message-group {.tc(); .p(0); .flex(); .flex-dc(); .gap(16); line-height: 16px;
      .content-title{.c(black); .fs(16); font-weight:500;}
      .content-message{.c(#737373); .fs(12); font-weight:400; .ph(30)}
    }
  }
  .result{ .flex(); .justify-center(); .items-center(); .flex-dc(); .h(100%); .gap(8);
    .icon{.fs(36); .c(#249135);}
    .content-message{.c(black); .fs(14); font-weight:500; .ph(30); .tc();}
  }
  .footer { .flex(); .flex-dr(); .gap(20); }

  .gp-modal-floating {.abs(); .b(20); .w(100%); .ph(20); .flex(); .gap(10);}

}
</style>