<template>
  <div my-info-notice>
    <label><FontIcon name="exclamation-triangle" /><span>{{$t('notice')}}</span></label>
    <p>
      <span class="content">{{ $t('myInfo.noticePok1') }}</span>
    </p>
    <p>
      <span class="content">{{ $t('myInfo.noticePok2') }}
        <button class="link" @click="click">{{$t('myInfo.startVerification')}}</button>
      </span>
    </p>
  </div>
</template>

<script>
import FontIcon from '@shared/components/common/FontIcon.vue';
import { restrictType } from '@/constants/base/onboarding/kyc';

export default {
  name: 'MyInfoNotice',
  components: { FontIcon },
  methods:{
    click(){
      this.$myinfo.redirectKycUrl({ redirectPath: 'info', lang: this.$i18n('lang'), restrict: restrictType.Withdrawal, });
      return;
    }
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[my-info-notice] { .flex(); .flex-dc(); .bgc(#EBEBEF); .br(16); .p(16); .mb(16);
  >label{.fs(14); font-weight: 400; .c(#000); .flex(); .gap(4); .items-center();
    [font-icon]{.c(#F7A609);}
    >span{flex:1;}
  }
  >p {.fs(12);  line-height: 16px; .pl(18);
    >span{.c(#737373);}
    >span .link{.pl(4); .c(#1570EF) !important; .fs(12);}
  }
}
</style>